import { FC } from 'react';
import { UserAvatar, PopUpList, useOutsideClick } from '@workspace/4Z1.uikit.react';
import useUserMenu from '../models/useUserMenu';
import './styles.scss';

const UserMenu: FC = () => {
  const { isActiveUser, setIsActiveUser, menuEntries, onMenuClick } =
    useUserMenu();
  
  const popupRef = useOutsideClick(() => {
    if(isActiveUser){
      setIsActiveUser(false);
    }
  })
  return (
    <div className='userMenu' ref={popupRef}>
      <UserAvatar
        name='Test User'
        active={isActiveUser}
        onClick={() => setIsActiveUser(!isActiveUser)}
      />
      <PopUpList
        list={menuEntries}
        isOpen={isActiveUser}
        onClick={onMenuClick}
      />
    </div>
  );
};

export default UserMenu;
