import moment from 'moment';

import map from './ru-RU/map';
import menu from './ru-RU/menu';
import modules from './ru-RU/module';
import user from './ru-RU/user';
import login from './ru-RU/login';
import violation from './ru-RU/violation';
import flight from './ru-RU/flight';
import video from './ru-RU/video';
import photo from './ru-RU/photo';
import online from './ru-RU/online';
import ofp from './ru-RU/ofp';
import gaz from './ru-RU/gaz';
import massload from './ru-RU/massload';
import statistic from './ru-RU/statistic';
import route from './ru-RU/route';
import tile from './ru-RU/tile';
import report from './ru-RU/report';
import order from './ru-RU/order';
import contextmenu from './ru-RU/contextmenu';
import access from './ru-RU/access';
import sk42 from './ru-RU/sk42';
import mark from './ru-RU/mark';
import asset from './ru-RU/asset';
import layer from './ru-RU/layer';
import dtoe from './ru-RU/dtoe';
import ftp from './ru-RU/ftp';
import changeAssetCoordinates from './ru-RU/change-asset-coordinates';
import revokedevice from './ru-RU/revoke-device';
import tabs from './ru-RU/tabs';
import download from './ru-RU/download';
import invertActions from './ru-RU/invert-actions';
import changeDir from './ru-RU/change-asset-direction';
import confirmation from './ru-RU/confirmation';
import diskSpace from './ru-RU/disk-space';

export default {
  'navBar.lang': 'Язык',
  'button.Save': 'Сохранить',
  'button.More': 'Подробнее',
  'button.Skip': 'Пропустить',
  'button.Upload': 'Выберите папку',
  'button.UploadUSB': 'Загрузка USB',
  'button.UploadImage': 'Добавить изображениe',
  'button.UploadFiles': 'Добавить изображения',
  'button.Arhive': 'Архив загрузок',
  'button.Abort': 'Отмена',
  'button.downloadStartSoon': 'Загрузка скоро начнется',
  'button.Edit': 'Редактировать',
  'button.Delete': 'Удалить',
  'button.Cancel': 'Отменить',
  'button.Create': 'Создать',
  'button.Add': 'Добавить',
  'button.View': 'Просмотр',
  'button.Download': 'Скачать',
  'button.Access': 'Доступ',
  'button.Apply': 'Принять',
  'button.DeleteAll': 'Удалить все',
  'button.SelectAll': 'Выбрать всё',
  'button.Select': 'Выбрать',
  'button.AllPhotos': 'Все фото',
  'button.Reset': 'Сбросить',
  'button.selected': '{count, plural, =0 {нет объектов} one {Выделен {count} объект} few {Выделено {count} объекта} other {Выделено {count} объектов}}',
  'button.startTiffProcessing': 'Запустить обработку GeoTiff',
  'button.AddFiles': 'Добавить файлы',
  'button.DownloadAllPhotos': 'Скачать фото',
  'button.DownloadKml': 'KML',
  'button.FTP': 'FTP',
  videos: 'Видео',
  photos: 'Фото',
  gazes: 'Газ',
  flight: 'Полёт',
  'pc.': 'шт.',
  from: 'из',
  'coordinates.lon': 'Долгота',
  'coordinates.lat': 'Широта',
  'ranges.Today': 'Сегодня',
  'ranges.This Month': 'За ' + moment.months(moment().month()),
  'ranges.Month': 'Месяц',
  'ranges.Year': 'Год',
  Loading: 'Загрузка',
  'Sure to delete?': 'Уверены?',
  'Please Input': 'Пожалуйста, введите',
  'Please select ofp': 'Пожалуйста, выберите ОФП',
  Undefined: 'Не установлено',
  'home.subtitle': 'Загруженные данные за последние 2 дня',
  change_date: 'Сменить период',
  SelectAll: 'Выбрать все',
  'license.active.expires': 'Лицензия истекает',
  'license.invalid': 'Лицензия не прошла валидацию',
  'license.inactive': 'Лицензия не активна',
  'input.helper.typeNumber': 'Введите число',
  'input.helper.typeText': 'Введите текст',
  403: 'Доступ запрещён',
  404: 'Страница не найдена',
  'Empty': 'На этой странице сейчас пусто. Проверьте позже.',
  'CheckLater': 'Проверьте позже',
  'Back Home': 'На главную',
  'Action': 'Действие',
  Preview: 'Предпросмотр',
  Remove: 'Удаление',
  holdposition: 'Закрепить масштаб',
  rows: 'записей',
  Selected: 'Выбрано',
  ...map,
  ...menu,
  ...user,
  ...login,
  ...violation,
  ...flight,
  ...photo,
  ...video,
  ...online,
  ...ofp,
  ...gaz,
  ...modules,
  ...massload,
  ...statistic,
  ...route,
  ...tile,
  ...report,
  ...order,
  ...contextmenu,
  ...access,
  ...sk42,
  ...mark,
  ...asset,
  ...layer,
  ...dtoe,
  ...ftp,
  ...changeAssetCoordinates,
  ...revokedevice,
  ...tabs,
  ...download,
  ...invertActions,
  ...changeDir,
  ...confirmation,
  ...diskSpace,
};
