import { Languages, Locale, LocalStorageKeys } from '../model';
import { LOCALES } from '../utils/locales';
import antdRu from 'antd/lib/locale/ru_RU';
import antdEn from 'antd/lib/locale/en_US';
import ruRU from '@/locales/ru-RU';
import enUS from '@/locales/en-US';
import { makeAutoObservable } from 'mobx';
import {
  getLocalStorageItems,
  setLocalStorageItems,
} from '../utils/localStorage';

export class LocaleStore {
  locale: Locale = LOCALES[Languages.RU];

  constructor() {
    makeAutoObservable(this);
    this.preloadLocale();
  }

  setLocale(locale: Locale) {
    this.locale = locale;

    setLocalStorageItems(LocalStorageKeys.ClientLocale, this.locale.code);
  }

  private preloadLocale() {
    const cachedLocale = this.findLocale(this.cachedLocale);

    if (cachedLocale) {
      this.locale = cachedLocale;
    }
  }

  private findLocale(key?: Languages): Locale | undefined {
    const locales = Object.values(LOCALES);

    return locales.find((locale) => locale.code === key);
  }

  private get cachedLocale() {
    return getLocalStorageItems(LocalStorageKeys.ClientLocale);
  }

  get libLocales() {
    switch (this.locale.code) {
      case Languages.RU:
        return {
          antd: antdRu,
          intl: ruRU,
        };

      case Languages.EN:
        return {
          antd: antdEn,
          intl: enUS,
        };

      default:
        break;
    }
  }
}
