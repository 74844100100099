import { RootStore } from '@/shared/store/root';
import { RootAppContext } from '@/shared/store/rootAppContext';
import { createContext, useContext } from 'react';

export const StoreContext = createContext<RootStore | undefined>(undefined)

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('You have forgotten to wrap your root component with RootStoreProvider');
  }
  return context;
}

export const AppContext = createContext<RootAppContext | undefined>(undefined)

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('You have forgotten to wrap your root component with RootStoreProvider');
  }
  return context;
}
