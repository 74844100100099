import { Container, interfaces } from 'inversify';
import { DiKeys } from '@/shared/di/global';
import { UserOptionsFromLocalStorage } from '@/entities/userOptions/UserOptionsFromLocalStorage';
import { UploaderStore } from '@/features/UploadFiles/store/uploadFiles.store';
import { MassloadApiImpl, TusApi, UploadService } from '@/entities/fileUpload';
import { UsbUploadApiImpl } from '@/entities/fileUpload/api/usb.upload.api';
import { PageLayoutStore } from '@/layouts/PageLayout/model/pageLayout.store';


const container: interfaces.Container = new Container();
container.bind(PageLayoutStore.diKey).to(PageLayoutStore).inSingletonScope();
container.bind(DiKeys.userOptions).to(UserOptionsFromLocalStorage).inSingletonScope();
container.bind(UploaderStore.diKey).to(UploaderStore).inSingletonScope();
container.bind(TusApi.diKey).to(TusApi).inSingletonScope();
container.bind(UploadService.diKey).to(UploadService).inSingletonScope();
container.bind(DiKeys.api.massload).to(MassloadApiImpl).inSingletonScope();
container.bind(DiKeys.api.usbupload).to(UsbUploadApiImpl).inSingletonScope();

const GlobalContainer = container;
export default GlobalContainer;
