import {
  FileUpload,
  UploadingFileStates,
  UploadSession
} from './interface';
import { makeAutoObservable, observable } from 'mobx';
import { v4 as generateId } from 'uuid';
import { logger } from '@workspace/4Z1.ts.utils';
import { TusApi } from '../api/tus.api';
import { MassloadApi } from '../api/Massload.api';

const log = logger('T:UPLD:S');

// TODO а нужен ли он нам в вообще - может сразу использовать FileUpload?
interface FileUploadStatus {
  readonly id: string;
  readonly file: File;
  readonly bytesTotal: number;
  readonly bytesUploaded: number;
  readonly error?: string;
  readonly success?: boolean;
}

function uploadStatus(upload: FileUploadStatus): UploadingFileStates {
  if (upload.error !== undefined) {
    return UploadingFileStates.Failed;
  }

  if (upload.success) {
    return UploadingFileStates.Completed;
  }

  return UploadingFileStates.Running;
}


/**
 * Отвечает за статус загрузки пачки файлов закинутых одномоментно
 */
export class TusUploadSession implements UploadSession {
  public readonly type = 'files';
  private readonly uploads: Map<string, FileUploadStatus> = observable.map();

  private _isStarting = false;

  public readonly id: string = generateId();

  constructor(
    files: readonly File[],
    private readonly tus: TusApi,
    private readonly massload: MassloadApi,
  ) {
    makeAutoObservable(this);
    this.start(files);
  }

  private start(files: readonly File[]): void {
    this._isStarting = true;
    const preUploads = files.map(file => {
      const id = generateId();
      const uploadState: FileUploadStatus = {
        id,
        file,
        bytesTotal: file.size,
        bytesUploaded: 0,
      };
      this.uploads.set(id, uploadState);
      return uploadState;
    });

    this.startUpload(preUploads);
    this._isStarting = false;
  }

  private startUpload(requests: readonly FileUploadStatus[]) {
    this.tus.upload(
      this.id,
      requests,
      (id: string, bytesUploaded: number, bytesTotal: number) => {
        this.handleUpdates(id, { bytesUploaded, bytesTotal });
      },
      (id: string, error: Error) => {
        this.handleUpdates(id, { error: error.message ?? 'Upload failed' });
      },
      (id: string) => {
        this.handleUpdates(id, { success: true });
      }
    );
  }

  public get bytesToUpload(): number {
    return [...this.uploads.values()]
      .reduce((val, upload) => val + upload.file.size, 0);
  }

  public get bytesUploaded(): number {
    return [...this.uploads.values()]
      .reduce((val, upload) => val + upload.bytesUploaded, 0);
  }

  public get files(): readonly FileUpload[] {
    return [...this.uploads.values()]
      .map(upload => {
        return {
          fileName: upload.file.name,
          state: uploadStatus(upload),
          error: upload.error,
          bytesUploaded: upload.bytesUploaded,
          size: upload.file.size,
          progress: upload.bytesUploaded / upload.file.size * 100,
          onClose: () => {
            this.abortUpload(upload);
          },
        };
      });
  }

  public get isFinished(): boolean {
    return [...this.uploads.values()].every(upload => upload.success || upload.error !== undefined);
  }

  public get isStarting(): boolean {
    return this._isStarting;
  }

  private handleUpdates(id: string, updates: Partial<FileUploadStatus>) {
    const upload = this.uploads.get(id);
    if (upload === undefined) {
      return;
    }
    this.uploads.set(id, { ...upload, ...updates });
  }

  private abortUpload(upload: FileUploadStatus) {
    this.uploads.delete(upload.id);
    this.tus.abortFile(upload.id);

    this.massload.getFiles(this.id).then(files => {
      const file = files.find(item => item.filename === upload.file.name);
      if (file !== undefined) {
        this.massload.delete(file.id).catch(e => {
          log.warn('failed to delete file', upload.file.name, upload.id, file, e);
        });
      }
    }).catch(e => {
      log.warn('failed to delete file - cann not get files in upload', upload.file.name, upload.id, e);
    });
  }
}
