import { UserOptions } from './interface';
import { Coordinates, Location } from '@workspace/4Z1.ts.utils';
import { injectable } from 'inversify';
import { get, set, merge } from 'lodash';
import moment from 'moment';

const LOCAL_STORAGE_KEY_USER_OPTIONS = 'currentOptions';
const LOCAL_STORAGE_KEY_CURRENT_USER = 'currentUser';
const DATE_FORMAT = 'DD.MM.YYYY';

@injectable()
export class UserOptionsFromLocalStorage implements UserOptions {

  constructor() {
  }

  private storage(): any | undefined {
    const raw = localStorage.getItem(LOCAL_STORAGE_KEY_USER_OPTIONS);
    if (raw === null) {
      return undefined;
    }
    return JSON.parse(raw);
  }

  private storageUser(): any | undefined {
    const raw = localStorage.getItem(LOCAL_STORAGE_KEY_CURRENT_USER);
    if (raw === null) {
      return undefined;
    }
    return JSON.parse(raw);
  }

  public get center(): Coordinates | undefined {
    const values = this.storage();
    if (values === undefined) {
      return undefined;
    }

    const center = get(values, 'center');
    return center !== undefined ? Location.parse(center) : undefined;
  }

  public get mainDateRange(): [Date, Date] | undefined {
    const dateRange = this.get<string[]>('date_range.main');
    if (dateRange !== undefined && Array.isArray(dateRange) && dateRange.length === 2) {
      const [start, end] = dateRange;
      const startDate = moment(start, DATE_FORMAT).toDate();
      const endDate = moment(end, DATE_FORMAT).toDate();
      return startDate && endDate ? [startDate, endDate] : undefined;
    }
    return undefined;
  }

  public setMainDateRange(start: Date, end: Date): void {
    const dateRange = [start, end].map(date => moment(date).format(DATE_FORMAT));
    this.set('date_range.main', dateRange);
  }

  public get ovenHost(): string | undefined {
    const values = this.storageUser();
    if (values === undefined) {
      return undefined;
    }

    const ovenHost = get(values, 'ovenHost');
    return ovenHost !== undefined ? ovenHost : undefined;
  }

  public get isLoggedIn(): boolean {
    const values = this.storageUser();

    return values !== undefined;
  }

  private get<T>(path: string): T | undefined {
    const currentOptions = this.storage() || {};
    return get(currentOptions, path);
  }

  private set(path: string, value: any): void {
    const currentOptions = this.storage() || {};
    const updatedOptions = merge({}, currentOptions, set({}, path, value));
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_OPTIONS, JSON.stringify(updatedOptions));
  }
}