import { ApiError } from '@/shared/errors';
import { getAuthToken } from '@/shared/utils/getAuthToken';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { isNil } from 'lodash';

export interface RestApi {
  get<Response, RequestParams = unknown>(
    path: string,
    params: RequestParams,
  ): Promise<Response>;
  post<Response, RequestParams = unknown>(
    path: string,
    data: RequestParams,
    queryParams?: unknown,
  ): Promise<Response>;
  delete<Response, RequestParams = unknown>(
    path: string,
    data: RequestParams,
  ): Promise<Response>;
}

export function createRestApi(throwOriginalError = false): RestApi {
  return new RestAxiosApi(axios, throwOriginalError);
}

class RestAxiosApi implements RestApi {
  private readonly client: AxiosInstance;

  constructor(
    private readonly api = axios,
    /**
     * @deprecated оставлен для совместимости старого кода
     * Если true - то будет брошен не наш ApiError, а оригинальная ошибка с подменой респонза
     * @private
     */
    private readonly throwOriginalError = false,
  ) {
    this.client = this.createClient();

    this.setInterceptors();
  }

  public get<Response, RequestParams = unknown>(
    url: string,
    params: RequestParams,
  ): Promise<Response> {
    return this.client.get(url, { params });
  }

  public post<Response, RequestParams = unknown>(
    url: string,
    data: RequestParams,
    queryParams?: unknown,
  ): Promise<Response> {
    if (queryParams === undefined) {
      return this.client.post(url, data);
    } else {
      return this.client.post(url, data, { params: queryParams })
    }
  }

  public delete<Response, RequestParams = unknown>(
    url: string,
    data: RequestParams,
  ): Promise<Response> {
    return this.client.delete(url, { data });
  }

  private createClient() {
    return this.api.create({ url: API });
  }

  private setInterceptors() {
    /**
     * Интерсептор для перехвата и парсинга ошибок
     * @see - shared/error.ts
     */
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (!error.response?.data) {
          return Promise.reject(error);
        }
        const errorResponseData = error.response.data;

        const parsedError = ApiError.parse(errorResponseData, error);

        if (isNil(parsedError)) {
          return Promise.reject(error);
        }

        if (this.throwOriginalError) { // этот код устарел, и должен быть выпилен, как не будет использования throwOriginalError
          error.response.data = parsedError;
          return Promise.reject(error);
        }

        return Promise.reject(parsedError);
      },
    );

    this.client.interceptors.request.use((request) => {
      const token = getAuthToken();

      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    });
  }
}

/**
 * @deprecated - Используем новый клиент api
 * @see - api/rest/AxiosApiClient
 */
export class UmiApiClient {
  client: any;

  constructor(client: any) {
    this.client = client;
    this.setInterceptors();
  }

  async get(path: string, query: string): Promise<any> {
    return this.client.get(path, query);
  }

  async post(path: string, data: any): Promise<any> {
    return this.client.post(path, { data });
  }

  async delete(path: string, data?: any): Promise<any> {
    return this.client.delete(path, { data });
  }

  private setInterceptors() {
    if (!this.client?.interceptors) {
      return console.error('RestApi interceptors not supported');
    }

    this.client.interceptors.request.use((url: string, options: any) => {
      const token = getAuthToken();
      if (!token) return;

      options.headers.Authorization = `Bearer ${token}`;
    });
  }
}
